import { makeVar, useReactiveVar } from '@apollo/client'
import { type } from 'os';


// --- MQTT ---
interface connectorMQTTType {
    uid: string,
    fid: string,
    cid: string,
    client: any;
    client2: any;
}

export const connectorMQTT = makeVar<connectorMQTTType>({ uid:'', fid:'',cid:'',client: null ,client2: null });

// --- BLE ---
interface connectorBLEType {
    name: string;
    device: any;
    server: any;
    chSignal: any;
    chData: any;
    chRule: any;
}

export const connectorBLE = makeVar<connectorBLEType>({ name:"",device: null, server: null, chSignal: null, chData: null, chRule: null });


// --- flow process variables ---
interface outputType {
  output: string;
  pt: number;
  wt: number;
}

interface historyType {
    cardid: string;
    select: string;
    outputs: Array<outputType>;
    pt: number;
    wt: number;
}

interface cardLinkType {
    svcCode: string;
    appTitle: string;
    flowid: string;
    cardid: string;
    editcardid: string;
    flowcardid: string;
    goCards: string[];
    busy: boolean;

    outputs: [];
    selected: [];
    message:boolean;
//    history: Array<historyType>;
    version: string;
    dev: boolean;
}

export const cardLink = makeVar<cardLinkType>({ svcCode: "mbisq", appTitle: "mbisq", flowid: "", cardid: "", editcardid: "", flowcardid: "", goCards:[], outputs:[], selected:[], 
message:false, busy:false,
version: "15.22", dev: false });
//outputs:[], selected:[], history: [], version: "15.22", dev: false });

// --- card view ---

interface cardViewType {
    mobile: boolean;
    width: number;
    height: number;
    src: number; // src 0:liflo, 1: cf - static
}
export const cardView = makeVar<cardViewType>({ mobile: true, width: 200, height: 200, src: 0 }); 


// --- user data ---
interface userDataType {

    // user 
    code: string; // tempo login code
    
    uid: string;  // user card id 
    uname: string; // email id
    nickname: string;
    svcuid: string;  // instance id for user 
    index: string; // id for service access
    fid: string; // user flow id
    token: string;
    type: string; // user type

//    targets: any; // target list
//    tasks: any; // task list

    data: any;

    // process
    scut: number; // https://fd.liflo.io/id/# shorotCut index 
    cfid: string; // this fid
    sfid: string; // launcher fid
    progress: number;

    // layer --------
    popLayer: string; // layerview type
    lfid: string; // layerview fid
    lidx: number; // layerview index
    fixed: boolean;

    // card view --------------
    width: number;
    height: number;
    ratio: number;
    reSize: boolean;
    update: number;
}

export const userDatas = makeVar<userDataType>({
    code: '', 
    uid:'', uname:'', nickname:'', svcuid:'', index: '', fid: '', token: '',type: '',
//    targets: [], tasks: [],
    data:{}, 
    scut: 0, cfid: '', sfid: '', progress: -1, 
    popLayer: '', lfid: '', lidx: 0, fixed: false,
    width:100, height:100, ratio:64, reSize:true, update:0});

// --- exam result related result data --- to be deprecated

interface resultDataType {
    id: string;
    out: string;
    data: any;
}
export const resultDatas = makeVar<resultDataType>({id:'', out:'',data:{}});

// --- task related result data ---

interface taskDataType {
    history: any; //<historyType>{};
    import: any;
    task:any;
}
export const taskDatas = makeVar<taskDataType>({ history: {}, import:{}, task:{}});

// --- task related result data ---
export const isKeepLoginBySocialLogin = makeVar(false);

interface ymDataType {
    uid: string; // service user id 
    uname: string; // service user name
    udata: any;
    targets:any;
    tasks:any;
    // current selected target & task
    gid: string;
    gdate: number;
    gregion: string;
    gcode: string,
    gname: string;

    tgdata: any;
    tgidx: number;
    tgname: string;

    tgid: string;
    bmonth: number;
    exmonth: number;

    tkid: string;
    tk: any;
}

export const ymDatas = makeVar<ymDataType>({
    uid:'', uname:'', udata:{}, 
    targets: [], tasks: [],
    gid:'', gdate:0, gregion:'', gcode:'A', gname:'', 
    tgdata: {}, tgid:'', tgname:'', tgidx:0, bmonth:0, exmonth:0,
    tkid: '', tk: {}});


// --- game data ---
interface gameDataType {
    step: number;
    stage: number;
    time: number;
    score: number;
    lives: number;
    dev: boolean;
}
export const gameDatas = makeVar<gameDataType>({step:0, stage:0, time: 0, score:0, lives:3, dev:false});
/*
export function SetStep( step:any) {
    const gameData = useReactiveVar(gameDatas);
    gameDatas({...gameData, step:step});
}

export function SetStage( stage:any) {
    const gameData = useReactiveVar(gameDatas);
    gameDatas({...gameData, stage:stage});
}
*/


// --- eyeTrack ---
interface positionType {
    x:number,
    y:number,
    z:number,
}
interface eyeTrackType {
    position: positionType;
    glabella: positionType;
    face: positionType;
    iris: positionType;
    distance: number;
}
export const eyeTrack = makeVar<eyeTrackType>({ position:{x:0,y:0,z:0},glabella:{x:0,y:0,z:0}, distance:0
    ,face:{x:0,y:0,z:0} ,iris:{x:0,y:0,z:0} });

// ActionUnit 
interface actionUnitType {
    data: any;
}

export const REACT_APP_GOOGLE_CLIENT_ID="478969637356-03rbebfdncp8hg20c6rhdkv7kjv596uh.apps.googleusercontent.com"
export const REACT_APP_GOOGLE_CLIENT_SECRET="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"
export const REACT_APP_KAKAO_CLIENT_ID="152737b21c429d0ef1db0fa3dcd008c4"
export const REACT_APP_NAVER_CLIENT_ID="NpAKtHDmkoRfAkkygnDS"

export const actionUnit = makeVar<actionUnitType>({ data:{} });
